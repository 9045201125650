<template>
  <div >
      <div v-if="!isShowLog">
        <SEARCH :searchChange='onCallbackSearch' :list="searchArr"></SEARCH>
        <TABS :tabChange='onCallbackTab' :list="tabsArr"></TABS>
        <TABLE :selectChange='onCallbackSelect' :buttonChange='onCallbackButton' :list="tableArr" :data="dataArr"></TABLE>
        <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>

        <!-- 添加 or 编辑设备 -->
        <add-face  :data='bdata' @close='onCallbackClose' v-if="isShowAdd"></add-face>
      </div>

      <div v-else>
        <NAVBAR :backChange='onCallbackBack' title="识别记录"></NAVBAR>
      <face-log :id="showTypeMemID"></face-log>
    </div>
  </div>
</template>

<script>
import NAVBAR from '@/components/navbar' // 头部
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import { getFace, getFaceDetaul, delFace } from '@/api/bind'
import faceLog from '@/components/device/faceLog' // 识别记录
import addFace from './components/addFace'
export default {
  name: 'FaceBind',
  components: {
    NAVBAR,
    SEARCH,
    TABS,
    TABLE,
    PAGE,
    faceLog,
    addFace,
  },
  data() {
    return {
      searchArr: [
        {
          name: '设备名称',
          type: 'input',
          options: [],
          key: 'projectName',
        },
        {
          name: '设备编号',
          type: 'input',
          key: 'id',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '添加绑定',
          func: 'openAdd',
        },
        {
          type: 'danger',
          name: '批量删除',
          func: 'remove',
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'name',
          label: '绑定对象',
          width: '150',
        },
        {
          prop: 'unit_name',
          label: '设备名称',
          width: '150',
        },
        {
          prop: 'unit_member',
          label: '设备编号',
          width: '180',
        },
        {
          prop: 'address',
          label: '地理位置',
          width: '180',
        },
        {
          prop: 'online',
          label: '在线状态',
          width: '120',
          type: 'tag',
        },
        {
          prop: 'status',
          label: '设备状态',
          width: '120',
          type: 'tag',
        },
        {
          prop: 'auth',
          label: '相关数据',
          type: 'other',
        },
        {
          prop: '',
          label: '操作',
          width: '170',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '编辑',
          func: 'edit',
        },
        {
          type: 'danger',
          name: '删除',
          func: 'remove',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        unit_name: '',
        unit_member: '',
      },
      ids: '', // 要删除的id
      tabsIndex: 0, // tabs 按钮的索引
      other: [
        {
          type: '',
          name: '识别记录',
          func: 'open',
          component: 'faceLog',
        },
      ], // 单兵
      showType: '', // 打开的子组件
      showTypeID: '', // id
      showTypeMemID: '', // 设备号
      isShowLog: false, // 人脸识别
      isShowAdd: false,
      bdata: {},
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.formData.unit_id = this.$store.getters.USER_ID
      getFace(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach((item) => {
          item.buttons = this.butnArr
          item.online = {
            type: item.online === 1 ? 'success' : 'danger',
            name: item.online === 1 ? '在线' : '离线',
          }
          item.status = {
            type: item.status === 1 ? 'success' : 'danger',
            name: item.status === 1 ? '显示' : '隐藏',
          }
          item.other = this.other
        })
        this.dataArr = result.data
      })
    },

    // 打开新增设备弹窗
    openAdd: function() {
      this.isShowAdd = true
    },
    edit: function() {
      getFaceDetaul(this.ids).then(res => {
        this.bdata = res.result
        this.isShowAdd = true
      })
    },

    open: function(item, type) {
      this.isShowLog = true
      this.showTypeMemID = item.unit_member
      this.$store.commit('SET_BC', false)
    },

    // 关闭统计、添加
    onCallbackBack: function() {
      this.$store.commit('SET_BC', true)
      this.isShowLog = false
    },

    // 删除设备
    remove: function() {
      if (!this.ids) {
        this.$toast('请选择要删除的绑定人脸')
        return false
      }
      delFace(this.ids).then(res => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    onCallbackClose: function(event) {
      this.isShowAdd = false
      this.bdata = {}
      event && this._loadData()
    },

    // 收到搜索回调
    onCallbackSearch: function(data) {
      const { id, projectName } = data
      this.formData.unit_member = id
      this.formData.unit_name = projectName
      this._loadData()
    },
    // 收到 tabs 回调
    onCallbackTab: function({ item, index }) {
      this[item.func]()
    },

    // 收到 选择 回调
    onCallbackSelect: function(ids) {
      this.ids = ids
    },
    // 收到 表格 内方法 回调
    onCallbackButton: function(item, func, type) {
      this.ids = item.id
      this[func](item, type)
    },
    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped></style>
