<template>
    <el-dialog
   :close-on-click-modal='false'
   :close-on-press-escape='false'
   :destroy-on-close='true'
   :show-close='false'
    width="35%" :title="`${isEdit ? '编辑' : '添加'}绑定`" :visible.sync="isShow">
    <!-- <select-map :localChange='onCallbackMarker' ref='map'></select-map> -->
    <div class="all-input">
      <div style="width: 100%!important;" class="item-input">
        <div class="label"><span class="red">*</span>选择工程：</div>
        <el-select
          filterable
          @change="clearID"
          style="width: 50%;!important"
          v-model="formData.p_id"
          placeholder="请选择"
        >
          <el-option
            v-for="item in objType"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="width: 100%!important;" class="item-input">
        <div class="label"><span class="red">*</span>选择设备：</div>
        <el-select
          filterable
          style="width: 50%;!important"
          v-model="formData.u_id"
          placeholder="请选择"
        >
          <el-option
            v-for="item in uArr"
            :key="item.id"
            :label="item.unit_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="width: 100%!important;" class="item-input">
        <div class="label"><span class="red">*</span>地理位置：</div>
        <div style="width: 50%;!important" @click="openMap" class="map">{{ formData.address || '选择地图' }}</div>
      </div>
    </div>
    <select-map :localChange='onCallbackMarker' ref='map'></select-map>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addFace, updFace } from '@/api/bind'
import { getUSelect, getPSelect } from '@/api/common'
import selectMap from '@/components/selectMap'

export default {
  name: 'addFace',
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  components: {
    selectMap,
  },
  data () {
    return {
      isEdit: false,
      isShow: true,
      objType: [],
      formData: {
        p_id: '',
        u_id: '',
        lng: '',
        lat: '',
        address: '',
      },
      id: '',
      uArr: [], // 设备下拉
      address: '',
    }
  },
  created() {
    this._loadSelect()
    if (this.data.id) {
      this.isEdit = true
      const { p_id: pid, lng, lat, address, u_id: uid, id } = this.data
      this.formData = { p_id: pid, u_id: uid, id, lat, lng, address }
    }
  },
  methods: {
    _loadSelect: function() {
      getUSelect(6).then(res => {
        const { result } = res
        this.uArr = result
      })
      getPSelect().then(res => {
        this.objType = res.result
      })
    },
    clearID: function() {
      this.id = ''
    },
    submit: function() {
      // console.log(this.list)
      if (!this.formData.p_id) {
        this.$toast('请选择 选择工程')
        return false
      } else if (!this.formData.u_id) {
        this.$toast('请选择 选择设备')
        return false
      } else if (!this.formData.address) {
        this.$toast('请选择 地理位置')
        return false
      }
      console.log(this.formData)
      // this.formData.type = this.formData.type

      this.isEdit ? this.edit() : this.save()
    },
    save: function() {
      addFace(this.formData).then(res => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.$emit('close', 'success')
        }
      })
    },
    edit: function() {
      updFace(this.formData).then(res => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.$emit('close', 'success')
        }
      })
    },
    close: function() {
      this.isShow = false
      this.$emit('close')
    },
    openMap: function() {
      this.$refs.map.open()
    },

    // 获取当前坐标点
    onCallbackMarker: function({ address, location }) {
      // console.log('地址：', address)
      // console.log('经纬度：', location)
      this.formData.address = address
      this.formData.lat = location.lat
      this.formData.lng = location.lng
    },
  },
}
</script>

<style lang="stylus" scoped>

</style>
