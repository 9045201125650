<template>
  <div id="detail-z">
    <SEARCH :searchChange='onCallbackSearch' :list="searchArr"></SEARCH>
    <TABS :tabChange='onCallbackTab' :list="tabsArr"></TABS>
    <TABLE :selectChange='onCallbackSelect' :switchChange='onCallbackSwitch' :buttonChange='onCallbackButton' :list="tableArr" :data="dataArr"></TABLE>
    <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>
  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import { dateMap, downloadExcel } from '@/utils/util'
import { getUnitSelect } from '@/api/common'
import { getFaceLog, expFaceLog, delFaceLog } from '@/api/face'
export default {
  name: 'faceLog',
  components: {
    SEARCH,
    TABS,
    TABLE,
    PAGE,

  },
  data() {
    return {
      searchArr: [
        {
          name: '姓名',
          type: 'input',
          options: [],
          key: 'name',
        },
        {
          name: '设备类型',
          type: 'picker',
          label: 'name',
          value: 'id',
          options: [],
          key: 'projectType',
        },
        {
          name: '创建时间',
          type: 'date',
          key: 'createTime',
        },
      ],
      tabsArr: [
        {
          type: 'primary',
          name: '导出数据',
          func: 'download',
          isLoading: false,
        },
        {
          type: 'danger',
          name: '批量删除',
          func: 'removeFace',
          isLoading: false,

        },
      ],
      tableArr: [
        {
          prop: 'nickname',
          label: '姓名',
          width: '123',
        },
        {
          prop: 'unit_id.unit',
          label: '单位',
          width: '190',
        },
        {
          prop: 'unit_id.group',
          label: '分组',
          width: '145',
        },
        {
          prop: 'mobile',
          label: '手机号',
          width: '200',
        },
        {
          prop: 'serial',
          label: '刷脸设备',
          width: '200',
        },
        {
          prop: 'b_id',
          label: '携带手环',
          width: '200',
        },
        {
          prop: 'i_id',
          label: '携带单兵',
          width: '200',
        },
        {
          prop: 'create_time',
          label: '刷脸时间',
          width: '200',
        },
        {
          prop: 'all_score',
          label: '操作',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        nickname: '',
        unit_id: '',
        start_at: '',
        end_at: '',
        serial: '',
      },
      ids: '',
    }
  },
  props: {
    id: {
      type: [String, Number],
      default() {
        return ''
      },
    },
  },
  created() {},
  mounted() {
    this._loadData()
    this._loadSelect()
  },
  methods: {
    _loadSelect: function() {
      getUnitSelect().then(res => {
        this.searchArr[1].options = res.result
      })
    },
    _loadData: function () {
      this.formData.serial = this.id
      getFaceLog(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach(item => {
          item.buttons = [
            {
              type: 'danger',
              name: '删除',
              func: 'removeFace',
            },
          ]
        })
        this.dataArr = result.data
      })
    },

    download: function() {
      expFaceLog(this.ids).then(res => {
        const { result } = res
        result.list.map(s => {
          s.unit_id && Object.keys(s.unit_id).forEach(i => {
            s[i] = s.unit_id[i]
          })
          return s
        })
        downloadExcel({
          header: result.header,
          data: result.list,
          title: ['nickname', 'unit', 'group', 'mobile', 'serial', 'b_id', 'd_id', 'create_time'],
          name: '人员考勤',
        })
      })
    },

    removeFace: function() {
      delFaceLog(this.ids).then(res => {
        // console.log(res)
        this.$toast(res.message)
        if (res.status === 200) {
          this._loadData()
        }
      })
    },
    // 收到搜索回调
    onCallbackSearch: function(data) {
      const { createTime, name, projectType } = data
      const t = dateMap(createTime)
      this.formData.start_at = t[0]
      this.formData.end_at = t[1]
      this.formData.nickname = name
      this.formData.unit_id = projectType
      this._loadData()
    },
    // 收到 tabs 回调
    onCallbackTab: function({ item, index }) {
      this[item.func]()
    },

    // 收到 选择 回调
    onCallbackSelect: function(ids) {
      this.ids = ids
    },

    // 收到 table内方法 回调
    onCallbackButton: function(item, func) {
      this.ids = item.id
      this[func](item)
    },
    // 收到 表格内 switch 方法回调
    onCallbackSwitch: function(item, func) {
      this[func](item)
    },

    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped>

</style>
